import React, { useEffect } from "react";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";

import { Select } from 'antd';
import {
    getCountries
} from "@actions/countriesActions";

const { Option } = Select;

function AllowedCountriesSelect({countries, intl, getCountries, value, onChange, loading, onBlur, ...anyProps}) {

    useEffect(() => {
        getCountries();
    }, [])
    
    return (
        <Select
            showSearch
            mode="multiple"
            showArrow
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={intl.formatMessage({id: "select_country"})}
            optionFilterProp="children"
            {...anyProps}
            filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        >
            {
                countries.map(country => (<Option key={country.id} value={country.id}>{country.name}</Option>))
            }
        </Select>
    )
}


function mapStateToProps(state) {
    return {
        loading: state.loading,
        countries: state.countries
    }
}

const mapDispatchToProps = {
    getCountries
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AllowedCountriesSelect));