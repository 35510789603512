import React, { useState, useEffect } from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";

import { Select } from 'antd';
import {
    getCurrency
} from "@actions/currencyActions";

const { Option } = Select;

function CurrencySelect({currencies, intl, getCurrency, value, onChange, onBlur, loading, mode, getSelectedValue, ...anyProps}) {

    const [disabledValue, setDisabledValue] = useState([]);
    useEffect(() => {
        getCurrency();
    }, [])

    const updateDisabledValues = () => {
        setDisabledValue(getSelectedValue && getSelectedValue() || []);
    }

    useEffect(() => {
        updateDisabledValues();
    }, [currencies])

    return (
        <Select
            mode={mode != undefined? mode : "multiple"}
            showSearch
            placeholder={intl.formatMessage({id: "select_currency"})}
            optionFilterProp="children"
            value={value && typeof value != "object" && value.toString() || value}
            onBlur={onBlur}
            onChange={onChange}
            allowClear={true}
            onDropdownVisibleChange={updateDisabledValues}
            {...anyProps}
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        >
            {
                currencies.filter(currency => disabledValue.findIndex((item) => item?.currencyId == currency.id && value != item?.currencyId) === -1).map(currency => (<Option key={currency.id} value={currency.id.toString()}>{currency.name}</Option>))
            }
        </Select>
    )
}


function mapStateToProps(state) {
    return {
        loading: state.loading,
        currencies: state.currencies
    }
}

const mapDispatchToProps = {
    getCurrency
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CurrencySelect));