import React, {useEffect, useContext, useState} from "react";
import {connect} from "react-redux";
import {
    REQUEST_ADD_OPERATOR_API_KEY,
    REQUEST_EDIT_OPERATOR_API_KEY
} from "@actions/actionTypes";

import {Modal, Form, Button, Input, Space, Switch} from 'antd';
import {FormattedMessage, injectIntl} from "react-intl";
import classname from "classnames";
import {
    ResponseContext
} from "../../hooks/ResponseObserver";
import CurrencySelect from "../CurrencySelect";
import AllowedCountriesSelect from "../AllowedCountriesSelect";
import {
    addOperarorApiKay,
    editOperarorApiKay
} from "@actions/operatorApiKaysActions";

function AddEditApiAccessPopup({
                                   handleCancel,
                                   show,
                                   intl,
                                   apiKey,
                                   loading,
                                   operatorId,
                                   addOperarorApiKay,
                                   editOperarorApiKay
                               }) {
    const [form] = Form.useForm();
    const {subscribeForError, unSubscribeForError} = useContext(ResponseContext);
    const [fildeError, setFildeError] = useState(false);

    const chackIsValid = () => {
        setTimeout(() => {
            let isError = false;
            setFildeError(false);
            form.getFieldsError().some((field) => {
                isError = isError || field.errors.length > 0;
            })
            setFildeError(isError);
        }, 100)
    }

    const formChange = (d) => {
        chackIsValid();
    }

    useEffect(() => {
        if (apiKey.id) {
            subscribeForError(REQUEST_EDIT_OPERATOR_API_KEY, () => {
                handleCancel();
            }, apiKey.id);
        } else {
            subscribeForError(REQUEST_ADD_OPERATOR_API_KEY, () => {
                handleCancel();
            });
        }
        return () => {
            unSubscribeForError(REQUEST_ADD_OPERATOR_API_KEY);
            unSubscribeForError(REQUEST_EDIT_OPERATOR_API_KEY);
        }
    }, [])

    useEffect(() => {
        if (apiKey.id) {
            const u = Object.assign({}, apiKey);
            u.currencies = u.currencies.map(currency => currency.id.toString())
            form.setFieldsValue(u);
        }
    }, [apiKey])

    const onFinish = (data) => {
        data.operatorId = operatorId
        if (show === "add") {
            addOperarorApiKay(data)
        } else {
            editOperarorApiKay({...data, id: apiKey.id})
        }
    }

    return (
        <>
            {

                <Modal
                    title={show === "add" ? <FormattedMessage id='add_api_access'/> :
                        <FormattedMessage id='edit_api_access'/>}
                    centered
                    className={`formModal api-access-modal`}
                    visible={show}
                    width={596}
                    onCancel={() => handleCancel()}

                    footer={false}>
                    <Form
                        layout="vertical"
                        form={form}
                        name="api-key-form"
                        onFinish={onFinish}
                        onValuesChange={formChange}
                        scrollToFirstError
                        onFinishFailed={() => chackIsValid()}
                        validateTrigger="onBlur"
                    >
                        <Form.Item label={<FormattedMessage id='api_key_name'/>}
                                   rules={[
                                       {
                                           required: true,
                                           message: <FormattedMessage id='name_validator'/>
                                       },
                                       ({getFieldValue}) => ({
                                           validator(_, value) {
                                               const spaceRegExp = new RegExp(/^[^\s]+(\s+[^\s]+)*$/)

                                               if (value && !spaceRegExp.test(value)) {
                                                   return Promise.reject(intl.formatMessage({id: `spaces_are_not_allowed_on_start_or_end_of_value`}))
                                               }

                                               if (value && !(value.length >= 3 && value.length <= 255)) {
                                                   return Promise.reject(intl.formatMessage({id: `name_valid`}))
                                               }

                                               return Promise.resolve();
                                           },
                                       })
                                   ]}
                                   onBlur={() => chackIsValid()}
                                   name="name">
                            <Input autoComplete="off"
                                   placeholder={intl.formatMessage({id: "api_key_name_placeholder"})}/>
                        </Form.Item>
                        <Space direction="Horizontal" className="middle-area">
                            <Form.Item label={<FormattedMessage id='auth_callback_url'/>}
                                       rules={[
                                           {
                                               required: true,
                                               message: <FormattedMessage id='url_validator'/>
                                           },
                                           ({getFieldValue}) => ({
                                               validator(_, value) {
                                                   const urlPattern = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm)
                                                   if (value && !urlPattern.test(value)) {
                                                       return Promise.reject(intl.formatMessage({id: `entered_value_is_not_url`}));
                                                   }
                                                   if (value && !(value.length <= 256)) {
                                                       return Promise.reject(intl.formatMessage({id: `url_valid`}))
                                                   }
                                                   return Promise.resolve();
                                               },
                                           })
                                       ]}
                                       onBlur={() => chackIsValid()}
                                       name="authCallbackUrl">
                                <Input autoComplete="off"
                                       placeholder={intl.formatMessage({id: "type_auth_callback_url"})}/>
                            </Form.Item>
                            <Form.Item label={<FormattedMessage id='rollback_url'/>}
                                       rules={[
                                           {
                                               required: true,
                                               message: <FormattedMessage id='url_validator'/>
                                           },
                                           ({getFieldValue}) => ({
                                               validator(_, value) {
                                                   const urlPattern = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm)
                                                   if (value && !urlPattern.test(value)) {
                                                       return Promise.reject(intl.formatMessage({id: `entered_value_is_not_url`}));
                                                   }
                                                   if (value && !(value.length <= 256)) {
                                                       return Promise.reject(intl.formatMessage({id: `url_valid`}))
                                                   }
                                                   return Promise.resolve();
                                               },
                                           })
                                       ]}
                                       onBlur={() => chackIsValid()}
                                       name="rollbackCallbackUrl">
                                <Input autoComplete="off"
                                       placeholder={intl.formatMessage({id: "type_rollback_url"})}/>
                            </Form.Item>
                        </Space>
                        <Space direction="Horizontal" className="middle-area">
                            <Form.Item label={<FormattedMessage id='debit_callback_url'/>}
                                       rules={[
                                           {
                                               required: true,
                                               message: <FormattedMessage id='url_validator'/>
                                           },
                                           ({getFieldValue}) => ({
                                               validator(_, value) {
                                                   const urlPattern = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm)
                                                   if (value && !urlPattern.test(value)) {
                                                       return Promise.reject(intl.formatMessage({id: `entered_value_is_not_url`}));
                                                   }
                                                   if (value && !(value.length <= 256)) {
                                                       return Promise.reject(intl.formatMessage({id: `url_valid`}))
                                                   }
                                                   return Promise.resolve();
                                               },
                                           })
                                       ]}
                                       onBlur={() => chackIsValid()}
                                       name="debitCallbackUrl">
                                <Input autoComplete="off"
                                       placeholder={intl.formatMessage({id: "type_debit_callback_url"})}/>
                            </Form.Item>
                            <Form.Item label={<FormattedMessage id='credit_callback_url'/>}
                                       rules={[
                                           {
                                               required: true,
                                               message: <FormattedMessage id='url_validator'/>
                                           },
                                           ({getFieldValue}) => ({
                                               validator(_, value) {
                                                   const urlPattern = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm)
                                                   if (value && !urlPattern.test(value)) {
                                                       return Promise.reject(intl.formatMessage({id: `entered_value_is_not_url`}));
                                                   }
                                                   if (value && !(value.length <= 256)) {
                                                       return Promise.reject(intl.formatMessage({id: `url_valid`}))
                                                   }
                                                   return Promise.resolve();
                                               },
                                           })
                                       ]}
                                       onBlur={() => chackIsValid()}
                                       name="creditCallbackUrl">
                                <Input autoComplete="off"
                                       placeholder={intl.formatMessage({id: "type_credit_callback_url"})}/>
                            </Form.Item>
                        </Space>
                        <Space direction="Horizontal" className="middle-area">
                            <Form.Item label={<FormattedMessage id='bid_percentage'/>}
                                       onBlur={() => chackIsValid()}
                                       rules={[
                                           {
                                               required: true,
                                               message: <FormattedMessage id='bid_percentage_is_required'/>
                                           },
                                           ({getFieldValue}) => ({
                                               validator(_, value) {
                                                   const numberRegExp = new RegExp(/^\d+(\.\d{1,2})?\%?$/);
                                                   if (value && !numberRegExp.test(value) || parseFloat(value) > 100) {
                                                       return Promise.reject(intl.formatMessage({id: `percentage_is_not_valid`}));
                                                   }
                                                   return Promise.resolve();
                                               }
                                           })
                                       ]}
                                       name="bidPercentage">
                                <Input autoComplete="off"
                                       placeholder={intl.formatMessage({id: "type_bid_percentage"})}/>
                            </Form.Item>
                            <Form.Item label={<FormattedMessage id='currencies'/>}
                                       rules={[
                                           {
                                               required: true,
                                               message: <FormattedMessage id='currency_is_required'/>
                                           },
                                       ]}
                                       onBlur={() => chackIsValid()}
                                       name="currencies">
                                <CurrencySelect/>
                            </Form.Item>
                        </Space>

                        <Form.Item label={<FormattedMessage id='countries'/>}
                                //    rules={[
                                //        {
                                //            required: true,
                                //            message: <FormattedMessage id='countries_is_required'/>
                                //        },
                                //    ]}
                                //    onBlur={() => chackIsValid()}
                                   name="allowedCountries">
                            <AllowedCountriesSelect/>
                        </Form.Item>

                        <Form.Item className="MainModal--footer">
                            <button type="reset" className="miniBtn default" onClick={handleCancel}>
                                <FormattedMessage id='cancel'/>
                            </button>
                            <Button type="primary"
                                    htmlType="submit"
                                    style={{height: 36}}
                                    className={classname("miniBtn", {submit: fildeError})}
                                    loading={loading._add_operator_api_key || loading['_edit_operator_api_key' + apiKey.id]}
                                    disabled={fildeError}
                            >
                                <FormattedMessage id='save'/>
                            </Button>
                            <div className="error_box">
                                <span className="block"><FormattedMessage id={"there_are_errors_on_the_form"}/>.</span>
                                <span className="block"><FormattedMessage
                                    id={"please_fix_them_before_continuing"}/>.</span>
                            </div>
                        </Form.Item>
                    </Form>
                    {/* <span className="help-text">
                        <FormattedMessage id="indicates_a_required_field" />
                    </span> */}
                </Modal>
            }
        </>
    )
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
    }
}

const mapDispatchToProps = {
    addOperarorApiKay,
    editOperarorApiKay
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AddEditApiAccessPopup));