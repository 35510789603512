import React, {useEffect, useContext, useState} from "react";
import {connect} from "react-redux";
import {
    addCategory,
    editCategory
} from "@actions/categoriesActions";
import {
    REQUEST_ADD_CATEGORY,
    REQUEST_EDIT_CATEGORY
} from "@actions/actionTypes";

import {Modal, Form, Button, Radio, Select, Tooltip, Input} from 'antd';
import {FormattedMessage, injectIntl} from "react-intl";
import classname from "classnames";
import {
    ResponseContext
} from "../../hooks/ResponseObserver";
import {AvailableLanguage, AvailableLanguageKays} from "@constants/Enums";
import ImgUpload from "@components/ImgUpload/ImgUpload";
import { RedoOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';

function CategoryControlPopup({handleCancel, show, intl, category, loading, roles, addCategory, editCategory}) {
    const [form] = Form.useForm();
    const {subscribeForError, unSubscribeForError} = useContext(ResponseContext);
    const [fildeError, setFildeError] = useState(false);

    const chackIsValid = () => {
        setTimeout(() => {
            let isError = false;
            setFildeError(false);
            form.getFieldsError().some((field) => {
                isError = isError || field.errors.length > 0;
            })
            setFildeError(isError);
        }, 100)
    }

    const formChange = (d) => {
        if(d['status'] !== undefined) {
            setStatusSelect(d['status']);
        }
        chackIsValid();
    }

    useEffect(() => {
        if (category.id) {
            subscribeForError(REQUEST_EDIT_CATEGORY, () => {
                handleCancel();
            }, category.id);
        } else {
            subscribeForError(REQUEST_ADD_CATEGORY, () => {
                handleCancel();
            });
        }
        form.setFieldsValue()
        return () => {
            unSubscribeForError(REQUEST_ADD_CATEGORY);
            unSubscribeForError(REQUEST_EDIT_CATEGORY);
        }
    }, [])

    useEffect(() => {
        if(category.id) {
            const c = {
                name: category.name,
                icon: category.icon,
                iconHover: category.iconHover,
                iconPressed: category.iconPressed
            };
            category.translations.forEach(item => {
                c[item.language] = item.value;
            })
            form.setFieldsValue(c);
        }
    }, [category])

    const onFinish = (data) => {
        
        let bySend = {
            name: data.name,
            icon: data.icon,
            iconHover: data.iconHover,
            iconPressed: data.iconPressed,
            orderId: category.orderId || 1,
            translations: [
                {
                    value: data["en"] || "",
                    language: "en"
                }
            ]
        }
        AvailableLanguageKays.forEach(key => {
            if (data[key]) {
                bySend.translations.push({
                    value: data[key] || "",
                    language: key
                });
            }
        })
        if (show === "add") {
            addCategory(bySend)
        } else {
            editCategory({...bySend, id: category.id})
        }
    }

    return (
        <>
            {

                <Modal
                    title={show === "add" ? <FormattedMessage id='add_category'/> :
                        <FormattedMessage id='edit_category'/>}
                    centered
                    className={`formModal category-modal`}
                    visible={show}
                    width="calc(100vw - 200px)"
                    onCancel={() => handleCancel()}
                    
                    footer={false}>
                    <Form
                        layout="vertical"
                        form={form}
                        name="category-form"
                        onFinish={onFinish}
                        onValuesChange={formChange}
                        scrollToFirstError
                        onFinishFailed={() => chackIsValid()}
                        validateTrigger="onBlur"
                        >
                            <div className="scroll-height">
                                <div className="horizontal-row">
                                    <Form.Item name="icon"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: <FormattedMessage id='image_is_required'/>
                                                    }
                                                ]}
                                                label={<FormattedMessage id='normal_icon'/>}
                                                className="categoryImgUpload"
                                                >
                                        <ImgUpload icon={<PlusOutlined />}
                                                    isOnlyImg={true}
                                                    uploadText={<FormattedMessage id='click_the_plus_icon_to_upload_new_file'/>} />
                                    </Form.Item>
                                    <Form.Item name="iconHover"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: <FormattedMessage id='image_is_required'/>
                                                    }
                                                ]}
                                                label={<FormattedMessage id='hover_icon'/>}
                                                className="categoryImgUpload"
                                                >
                                        <ImgUpload icon={<PlusOutlined />}
                                                    isOnlyImg={true}
                                                    uploadText={<FormattedMessage id='click_the_plus_icon_to_upload_new_file'/>} />
                                    </Form.Item>
                                    <Form.Item name="iconPressed"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: <FormattedMessage id='image_is_required'/>
                                                    }
                                                ]}
                                                label={<FormattedMessage id='pressed_icon'/>}
                                                className="categoryImgUpload"
                                                >
                                        <ImgUpload icon={<PlusOutlined />}
                                                    isOnlyImg={true}
                                                    uploadText={<FormattedMessage id='click_the_plus_icon_to_upload_new_file'/>} />
                                    </Form.Item>
                                </div>
                                <Form.Item label={<FormattedMessage id='category_name'/>}
                                    style={{maxWidth: 204}}
                                        rules={[
                                            {
                                                required: true,
                                                message: <FormattedMessage id="category_name_validator"/>
                                            },
                                            ({getFieldValue}) => ({
                                                validator(_, value) {
                                                    const spaceRegExp = new RegExp(/^[^\s]+(\s+[^\s]+)*$/)

                                                    if (value && !spaceRegExp.test(value)) {
                                                        return Promise.reject(intl.formatMessage({id: `spaces_are_not_allowed_on_start_or_end_of_value`}))
                                                    }

                                                    if (value && !(value.length >= 2 && value.length <= 20)) {
                                                        return Promise.reject(intl.formatMessage({id: `category_name_valid`}))
                                                    }
    
                                                    return Promise.resolve();
                                                },
                                            })
                                        ]}
                                        onBlur={() => chackIsValid()}
                                        name="name">
                                    <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_user_name"})}/>
                                </Form.Item>
                                <div className="horizontal-row">
                                <Form.Item  label={<FormattedMessage id="English"/>}
                                        rules={[
                                            {
                                                required: true,
                                                message: <FormattedMessage id='the_field_is_required'/>
                                            },
                                            ({getFieldValue}) => ({
                                                validator(_, value) {
                                                    const spaceRegExp = new RegExp(/^[^\s]+(\s+[^\s]+)*$/)

                                                    if (value && !spaceRegExp.test(value)) {
                                                        return Promise.reject(intl.formatMessage({id: `spaces_are_not_allowed_on_start_or_end_of_value`}))
                                                    }

                                                    if (value && !(value.length >= 2 && value.length <= 20)) {
                                                        return Promise.reject(intl.formatMessage({id: `lenguages_name_valid`}))
                                                    }
    
                                                    return Promise.resolve();
                                                },
                                            })
                                        ]}
                                        onBlur={() => chackIsValid()}
                                        name="en">
                                    <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_en"})}/>
                                </Form.Item>
                                    {AvailableLanguageKays.map(key => (
                                        <Form.Item key={key} label={<FormattedMessage id={AvailableLanguage[key]}/>}
                                        rules={[
                                            ({getFieldValue}) => ({
                                                validator(_, value) {
                                                    const spaceRegExp = new RegExp(/^[^\s]+(\s+[^\s]+)*$/)

                                                    if (value && !spaceRegExp.test(value)) {
                                                        return Promise.reject(intl.formatMessage({id: `spaces_are_not_allowed_on_start_or_end_of_value`}))
                                                    }

                                                    if (value && !(value.length >= 2 && value.length <= 20)) {
                                                        return Promise.reject(intl.formatMessage({id: "lenguages_name_valid"}))
                                                    }
    
                                                    return Promise.resolve();
                                                },
                                            })
                                        ]}
                                        onBlur={() => chackIsValid()}
                                        name={key}>
                                        <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_" + key})}/>
                                    </Form.Item>
                                    ))}
                                </div>
                            </div>

                        <Form.Item className="MainModal--footer">
                            <button type="reset" className="miniBtn default" onClick={handleCancel}>
                                <FormattedMessage id='cancel'/>
                            </button>
                            <Button type="primary"
                                    htmlType="submit"
                                    style={{height: 36}}
                                    className={classname("miniBtn", {submit: fildeError })}
                                    loading={loading._add_category || loading['_edit_category' + category.id]}
                                    disabled={fildeError}
                            >
                                <FormattedMessage id='save'/>
                            </Button>
                            <div className="error_box">
                                <span className="block"><FormattedMessage id={"there_are_errors_on_the_form"}/>.</span>
                                <span className="block"><FormattedMessage id={"please_fix_them_before_continuing"}/>.</span>
                            </div>
                        </Form.Item>
                    </Form>
                    <span className="help-text">
                        <FormattedMessage id="indicates_a_required_field" />
                    </span>
                </Modal>
            }
        </>
    )
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        roles: state.roles
    }
}

const mapDispatchToProps = {
    addCategory,
    editCategory
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CategoryControlPopup));