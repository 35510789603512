import React, { useState } from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";

import { Select } from 'antd';
import { useEffect } from "react";
import {
    getCategories
} from "@actions/categoriesActions";

const { Option } = Select;

function CategoriesSelect({categories, intl, getCategories, value, onChange, loading, ...anyProps}) {
    const [categoriesSortedByName, setCategoriesSortedByName] = useState([]);

    useEffect(() => {
        getCategories();
    }, [])

    useEffect(() => {
        setCategoriesSortedByName(categories.slice().sort((a, b) => {
                let aName = a.name.toLowerCase();
                let bName = b.name.toLowerCase();
                if (aName > bName) {
                    return 1;
                } else if (aName < bName) {
                   return -1;
                } else {
                    return 0;
                }
            }))
    }, [categories])

    
    return (
        <Select
            dropdownClassName="categories-dropdown"
            showSearch
            placeholder={intl.formatMessage({id: "select_category"})}
            optionFilterProp="children"
            value={value}
            onChange={onChange}
            {...anyProps}
            allowClear
            loading={loading._categories}
            filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        >
            {
                categoriesSortedByName.map(category => (<Option key={category.id} value={category.id}>{category.name}</Option>))
            }
        </Select>
    )
}


function mapStateToProps(state) {
    return {
        loading: state.loading,
        categories: state.categories
    }
}

const mapDispatchToProps = {
    getCategories
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CategoriesSelect));